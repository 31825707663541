import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Press from './pages/Press';
import PortfolioList from './pages/PortfolioList';
import Services from './pages/Services';
import PortfolioDetails from './pages/DetailedPortfolio';

function App() {
  return (
    <Router>
      <div>
        {/* Navbar or other common components can be placed here */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/press" element={<Press />} />
          <Route path="/Portfolio" element={<PortfolioList />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio/:id" element={<PortfolioDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
