import React from "react";
import Header from "../components/header";
import BasicGrid from "../components/ImageTitle";
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Fade
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { color } from "@mui/system";
import { makeStyles } from "@mui/styles";
import ServiceItem from "../components/ServiceItem";
import ImageSlider from "../components/ImageSlider";
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.5rem",
  },
}));

const services = [
  {
    title: "Service 1",
    description: "Description of Service 1",
    imgUrl:
      "https://media.istockphoto.com/id/481628382/photo/carpenter-taking-measurement.jpg?s=612x612&w=0&k=20&c=l2cAPfJL2bGltBasmnqUlsz2OHv6H6bUzjzhx0feOJg=",
  },
  {
    title: "Service 2",
    description: "Description of Service 2",
    imgUrl:
      "https://media.istockphoto.com/id/481628382/photo/carpenter-taking-measurement.jpg?s=612x612&w=0&k=20&c=l2cAPfJL2bGltBasmnqUlsz2OHv6H6bUzjzhx0feOJg=",
  },
  {
    title: "Service 3",
    description: "Description of Service 3",
    imgUrl:
      "https://media.istockphoto.com/id/481628382/photo/carpenter-taking-measurement.jpg?s=612x612&w=0&k=20&c=l2cAPfJL2bGltBasmnqUlsz2OHv6H6bUzjzhx0feOJg=",
  },
  {
    title: "Service 4",
    description: "Description of Service 1",
    imgUrl:
      "https://media.istockphoto.com/id/481628382/photo/carpenter-taking-measurement.jpg?s=612x612&w=0&k=20&c=l2cAPfJL2bGltBasmnqUlsz2OHv6H6bUzjzhx0feOJg=",
  },
  {
    title: "Service 5",
    description: "Description of Service 2",
    imgUrl:
      "https://media.istockphoto.com/id/481628382/photo/carpenter-taking-measurement.jpg?s=612x612&w=0&k=20&c=l2cAPfJL2bGltBasmnqUlsz2OHv6H6bUzjzhx0feOJg=",
  },
  {
    title: "Service 6",
    description: "Description of Service 3",
    imgUrl:
      "https://media.istockphoto.com/id/481628382/photo/carpenter-taking-measurement.jpg?s=612x612&w=0&k=20&c=l2cAPfJL2bGltBasmnqUlsz2OHv6H6bUzjzhx0feOJg=",
  },
];

function Services() {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleOnClick = () => {
    navigate("/contact");
  };

  return (
    <div>
      <Header />
      <Container>
      <ImageSlider />
      <Grid container spacing={4} sx={{margin: '1rem auto'}}>
            {services.map((service, index) => (
              <Grid item lg={4} xs={10} sm={10} md={6}>
                <ServiceItem
                  title={service.title}
                  description={service.description}
                  imageUrl={service.imgUrl}
                />
              </Grid>
            ))}
      </Grid>
      {/* <Button
          onClick={handleOnClick}
          type="submit"
          variant="contained"
          className={classes.button}
          sx={{
            margin: "1rem auto",
            display: "flex",
          }}
        >
          Contact Us
        </Button> */}
        <Button onClick={handleOnClick} variant="contained" sx={{
          margin: '1rem auto',
          display: 'flex'
        }}>Contact Us</Button>
    </Container>
      {/* <Box container className={classes.root}>
        {/* <BasicGrid /> */}
        {/* <Typography variant="h5">What Do We Offer</Typography>
        <Box>
          <Grid container spacing={2}>
            {services.map((service, index) => (
              <Grid item xs={4}>
                <ServiceItem
                  title={service.title}
                  description={service.description}
                  imageUrl={service.imgUrl}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Button
          onClick={handleOnClick}
          type="submit"
          variant="contained"
          sx={{
            margin: "auto",
            display: "flex",
            border: "1px solid black",
          }}
        >
          Contact Us
        </Button>
      </Box> */}
    </div>
  );
}

export default Services;
