import React from 'react';
import Header from '../components/header';
import CustomImageList from '../components/QuiltedImagelist';
import Footer from '../components/Footer';

function Home() {
  return (
    <div>
        <Header />
        <CustomImageList />
        <Footer />
    </div>
  );
}

export default Home;