import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Typography, Grid, Card, CardActionArea, CardMedia, CardContent, Box, Button } from '@mui/material';
import Header from '../components/header';
import { height } from '@mui/system';
import PortfolioDetails from './DetailedPortfolio';
// import ScrollToTopButton from '../components/ScrollToTopButton';

const portfolios = [
  { id: 1, title: 'Portfolio 1', imageUrl: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622' },
  { id: 2, title: 'Portfolio 2', imageUrl: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622' },
  { id: 3, title: 'Portfolio 3', imageUrl: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622' },
  { id: 4, title: 'Portfolio 1', imageUrl: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622' },
  { id: 5, title: 'Portfolio 2', imageUrl: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622' },
  { id: 6, title: 'Portfolio 3', imageUrl: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622' },
  { id: 7, title: 'Portfolio 1', imageUrl: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622' },
  { id: 8, title: 'Portfolio 2', imageUrl: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622' },
  { id: 9, title: 'Portfolio 3', imageUrl: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622' },
  // Add more portfolios as needed
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    overflowY: 'hidden',
    // padding: '1rem',
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: '349px',
    width: '90%',
  },
}));

function PortfolioList() {
  const classes = useStyles();
  const [portfolioToDetailed, setPortfolioToDetailed] = useState()

  const handleClick = (id) => {
    console.log(`Portfolio ${id} is called`);
    setPortfolioToDetailed(id);
    ScrollToTopButton()
  }

  const handleClose = () => {
    setPortfolioToDetailed();
  }

  const ScrollToTopButton = () => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Add smooth scrolling behavior
      });
    };
  }
  return (
    <div>
      <Header />
      <Box className={classes.root}>
      {portfolioToDetailed ? <Button onClick={handleClose} variant="contained" sx={{
          color: 'primary.beigeDark',
          margin: 'auto',
          display: 'flex'
        }}>Close Portfolio</Button> : <></>}
        <Grid container spacing={3} className={classes.root} sx={{ margin: 'auto' }}>
          {portfolioToDetailed ? <PortfolioDetails /> : <></>}
          {portfolios.map((portfolio) => (
            <Grid item key={portfolio.id} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                {/* <CardActionArea component={Link} to={`/portfolio/${portfolio.id}`}> */}
                <CardActionArea onClick={() => handleClick(portfolio.id)}>
                  <CardMedia
                    className={classes.media}
                    image={portfolio.imageUrl}
                    title={portfolio.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2" sx={{
                      textAlign: 'center',
                      color: 'primary.beigeDark'
                    }}>
                      {portfolio.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default PortfolioList;
