import React from 'react';
import {Card, CardMedia, Typography, Container } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import image1 from '../asset/images/david-pisnoy-46juD4zY1XA-unsplash.jpg';
import image2 from '../asset/images/dominik-scythe-3cIvvzjE6Lk-unsplash.jpg';
import image3 from '../asset/images/furniture_banner_795a28537a.jpg';

const images = [image3, image2, image1];

function ImageSlider() {
  return (
    <Container sx={{
      margin: '1rem auto'
    }}>
      <Carousel
        animation="slide"
        navButtonsProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}
      >
        {images.map((image, index) => (
          <Card key={index}>
            <CardMedia
              component="img"
              width='80%'
              height="450"
              image={image}
              alt={`Image ${index + 1}`}
            />
          </Card>
        ))}
      </Carousel>
    </Container>
  );
}

export default ImageSlider;
