import React from "react";
import Header from "../components/header";
import BasicGrid from "../components/ImageTitle";
import { Grid, Typography, Box, Fade } from "@mui/material";
import { makeStyles } from '@mui/styles';
import UserProfile from '../components/UserProfile';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem',
  },
  box:{
    margin: '2rem auto'
  },
  imageContainer: {
    textAlign: 'center',    
    marginTop: '1rem'
  },
  image: {
    width: '80%',
    height: 'auto',
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[5],
  },
  
}));

function About() {
  const classes = useStyles();
  return (
    <div>
      <Header />
      <Fade in timeout={1000}>
        <div className={classes.imageContainer}>
          <img
            src="https://www.aertsen.in/wp-content/uploads/2022/11/Stunning-Modern-Living-Room-Interior-Design-Ideas-in-India.jpg"
            alt="fdsdf"
            className={classes.image}
          />
        </div>
      </Fade>
      <Box container className={classes.root}>
        {/* <BasicGrid /> */}
        <Box className={classes.box}>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={10} md= {6} lg={4} sx={{margin:'auto'}}>
              {/* <img
                src="https://images.unsplash.com/photo-1549388604-817d15aa0110"
                alt=""
                width={"95%"}
                height={"auto"}
              /> */}
              <UserProfile />
            </Grid>
            <Grid item xs={10} sm={10} md= {6} lg={4} sx={{
                margin: 'auto',
                overflowXx: 'hidden',
                width: '100%'
              }}> 
              <Typography variant="h5" sx={{
                margin: 'auto',
                width: '100%',
                display: 'block',
              }}>
                CATHIE HONG PRINCIPAL DESIGNER
              </Typography>
              <Typography variant="body1" sx={{
                margin: 'auto',
                width: '100%',
                margin: '0.5rem auto' 
              }}>
                Cathie is the lead designer at CHI. She has always been an
                artist and has pursued it in many different forms throughout her
                life, but has found her niche in creating beautiful and
                functional spaces for a wide spectrum of clients. She enjoys the
                creativity of imagining the layout, materials and finishes of a
                space, but also enjoys the precision, calculations and careful
                coordination required in bringing that vision to life with a
                valued network of tradespeople. She has lived in San Diego,
                Orange County, Los Angeles, and has now settled back up in the
                Bay Area (her hometown) with her husband and four children. Her
                vision in designing interiors is to help clients actualize their
                dreams for their homes in a thoughtful and collaborative way.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default About;
