import React from 'react';
import Header from '../components/header';

function Press() {
  return (
    <div>
        <Header />
    </div>
  );
}

export default Press;