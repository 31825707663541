import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <img src="https://images.unsplash.com/photo-1549388604-817d15aa0110" alt="" width={'95%'} height={'auto'}/>
        </Grid>
        <Grid item xs={4}>
          <img src="https://images.unsplash.com/photo-1525097487452-6278ff080c31" alt=""  width={'100%'} height={'auto'}/>
        </Grid>
        <Grid item xs={4}>
          <img src="https://images.unsplash.com/photo-1523413651479-597eb2da0ad6" alt=""  width={'100%'} height={'auto'}/>
        </Grid>
      </Grid>
    </Box>
  );
}