import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

function ServiceItem({ title, description, imageUrl }) {
  const classes = useStyles();
  return (
    <Card sx={{ maxWidth: 300 }} className={classes.card}>
      <Grid container  alignItems="center">
        <Grid item xs={4}>
          <CardMedia
            component="img"
            height="auto"
            image={imageUrl}
            alt={title}
            sx={{
              width: "90%",
              margin: 'auto'
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ServiceItem;
