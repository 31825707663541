import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, TextField, Button, Grid, Box } from "@mui/material";
import Header from "../components/header";
import BasicGrid from "../components/ImageTitle";

const useStyles = makeStyles((theme) => ({
  root:{
    padding: '1rem'
  },
  container: {
    padding: theme.spacing(3),
  },
  form: {
    marginTop: theme.spacing(2),
    width: '50%',
    margin: 'auto'
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginTop: '1rem'

  },
}));

function Contact() {
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <div>
      <Header />
      <Box container className={classes.root}>
        <BasicGrid />
        <Typography variant="h4" gutterBottom sx={{
          textAlign: 'center'
        }}>
          Contact Us
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Full Name"
                variant="outlined"
                className={classes.textField}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email Address"
                variant="outlined"
                className={classes.textField}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                variant="outlined"
                className={classes.textField}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                variant="outlined"
                className={classes.textField}
                multiline
                rows={4}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.textField}
            sx={{
              display: 'flex',
              margin: '1rem auto',
              
            }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </div>
  );
}

export default Contact;
