import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, responsiveFontSizes  } from '@mui/material/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));

let theme = createTheme({
  palette: {
    primary: {
      main: "#FFFF",
      beigeDark: "#6F5B3E",
      beigeLight: "#C4AE78",
      contrastText: "#C4AE78"
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      color: '#6F5B3E',
    },
    h2: {
      color: '#6F5B3E',
    },
    h3: {
      color: '#6F5B3E',
    },
    h4: {
      color: '#6F5B3E',
    },
    h5: {
      color: '#6F5B3E',
    },
    h6: {
      color: '#6F5B3E',
    },
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    overrides: {
      MuiButton: {
        raisedPrimary: {
          color: '#C4AE78',
        },
      },
    }
  },
}
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={responsiveFontSizes(theme)}>
    <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
