import React from 'react';
import Avatar from '@mui/material/Avatar';

function UserProfile() {
  return (
    <div>
      <Avatar
        alt="Profile Picture"
        src="https://qph.cf2.quoracdn.net/main-qimg-e8a478b4b15e1c7c3b4f4ca77c1aec5e.webp"
        sx={{ width: '80%', height: '80%' , margin: 'auto'}} // Customize size as needed
        variant="circular" // Set variant to "circular" for a circular shape
      />
    </div>
  );
}

export default UserProfile;
