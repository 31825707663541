import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Grid, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(3),
    marginTop: 'auto', // Push the footer to the bottom of the viewport
  },
  footerContent: {
    textAlign: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: '2rem',
    verticalAlign: 'middle',
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Container>
        <div className={classes.footerContent}>
          {/* Social media icons */}
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Link href="https://web.whatsapp.com/" target="_blank" rel="noopener noreferrer" color="inherit">
                <WhatsAppIcon className={classes.icon} />
              </Link>
            </Grid>
            <Grid item>
              <Link href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" color="inherit">
                <InstagramIcon className={classes.icon} />
              </Link>
            </Grid>
          </Grid>
          {/* Contact details */}
          <Typography variant="body2">Phone: +1234567890</Typography>
          <Typography variant="body2">Email: example@example.com</Typography>
          {/* Other required details */}
          <Typography variant="body2">Address: 123 Main St, City, Country</Typography>
          <Typography variant="body2">© {new Date().getFullYear()} Your Company Name</Typography>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
