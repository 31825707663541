import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Typography, Box, Grid } from "@mui/material";
import TitlebarBelowMasonryImageList from "../components/TitlebarBelowMasonryImageList";
import Header from '../components/header';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: '1rem'
  },
  image: {
    maxWidth: "50%",
    height: "auto",
    marginBottom: theme.spacing(2),
  },
}));

function PortfolioDetails() {
  const classes = useStyles();

  // Assuming you have a function to fetch portfolio details by ID from some data source
  // You can replace this with your actual logic to fetch portfolio details
  //   const portfolioDetails = fetchPortfolioDetailsById(id);
  const portfolioDetails = {
    id: 1,
    location: "Pune",
    dateOfCompletion: "4th-March-2024",
    duration: "15 days",
    theme: "Retro",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti inventore doloribus autem deleniti aperiam obcaecati totam, explicabo provident? Blanditiis veritatis perspiciatis dolorum ab est omnis consectetur labore assumenda debitis nobis?",
    imageUrl: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
  };

  if (!portfolioDetails) {
    return <Typography variant="h6">Portfolio not found</Typography>;
  }

  const { description, location, theme, duration, dateOfCompletion } =
    portfolioDetails;

  return (
    <div>
      {/* <Header /> */}
      <Box container className={classes.root}>
        <Typography variant="h6" gutterBottom>
          <strong>Location:</strong> {location}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Theme: {theme}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Duration: {duration}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Date of Completion: {dateOfCompletion}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Description: {description}
        </Typography>
        {/* <img src={imageUrl} alt={title} className={classes.image} /> */}
        {/* <Grid xs={12} sm={6} md={4} spacing={3} sx={{
        height: 'auto',
        margin:'auto',
      }}>
      <CustomImageList />
      </Grid> */}
        <Box>
          <TitlebarBelowMasonryImageList />
        </Box>
      </Box>
      <hr></hr>
    </div>
  );
}

export default PortfolioDetails;
